import React, { useContext, useEffect, useState } from 'react'
import DatePicker from '../../../components/atoms/Datepicker/DatePicker'
import Modal from '../../../components/atoms/Modal/Modal'
import { AuditMonthContext } from '../../../context/AuditMonthContext'
import { DateToYYYYMM } from '../../../services/utils/date'

const dayjs = require('dayjs')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const ConfirmationModal = ({ visible, onModalClose, okAction = () => { }, tcData }) => {
  const { auditMonth } = useContext(AuditMonthContext)

  const [selected, setSelected] = useState({
    month: auditMonth.getMonth() + 1,
    year: auditMonth.getFullYear()
  })
  const [errMsg, setErrMsg] = useState('')

  const fetchNextMonth = async (month) => {
    /* Logic to fetch next month */
    /**
             * Fetch month from payload
             * Fetch end of current month
             * Add one day to it
             * With that fetch start of next month
             */

    const thisMonthLastDay = dayjs(month).endOf('month').$d
    const nextMonthFirstDay = dayjs(thisMonthLastDay).add(1, 'd')
    const nextMonth = dayjs(nextMonthFirstDay).startOf('month').$d

    return nextMonth
  }

  useEffect(() => {
    defaultSelection()
  }, [auditMonth])

  const defaultSelection = () => {
    setSelected({
      month: auditMonth.getMonth() + 1,
      year: auditMonth.getFullYear()
    })
  }

  const submitHandler = async (tc, month) => {
    try {
      const queryMonth = dayjs(`${month}-01T00:00:00.000Z`).$d
      const auditArray = tc.audit
      let lastAuditedMonth
      const tcCreatedMonth = tc.activatedOn

      /* Sort auditArray */
      auditArray.sort(function (a, b) {
        return new Date(a.month) - new Date(b.month)
      })

      for (let i = 0; i < auditArray.length; i++) {
        const audit = auditArray[i]

        if (dayjs(audit.month).isSameOrAfter(queryMonth)) {
          if (audit.lossPercentage) {
            lastAuditedMonth = audit.month
          }
        }
      }

      if (dayjs(queryMonth).isBefore(dayjs(tcCreatedMonth))) {
        throw new Error(`You cannot edit TC for ${dayjs(queryMonth).format('MMMM-YYYY')} since TC was created in ${dayjs(tcCreatedMonth).format('MMMM-YYYY')}.
                    So, please select a month after ${dayjs(tcCreatedMonth).format('MMMM-YYYY')}.`)
      }

      if (lastAuditedMonth) {
        if (!dayjs(queryMonth).isAfter(dayjs(lastAuditedMonth))) {
          const nextPossibleUpdateMonth = await fetchNextMonth(
            lastAuditedMonth
          )

          throw new Error(`TC has been audited for ${dayjs(
                            lastAuditedMonth
                        ).format('MMMM-YYYY')}, so please select a later month (${dayjs(
                            nextPossibleUpdateMonth
                        ).format(
                            'MMMM-YYYY'
                        )} onwards) to perform changes to Reading day, MR or CT Ratio`)
        }
      }
      onModalClose()
      okAction(month)
      defaultSelection()
    } catch (error) {
      setErrMsg(error)
    }
  }

  return (
    <Modal
      title='Select a month for which you want to edit changes.'
      onModalClose={onModalClose}
      visible={visible}
      okBtnDisabled={false}
      okBtnLoading={false}
      okAction={() => submitHandler(tcData, DateToYYYYMM(new Date(selected.year, (selected.month - 1), 1)))}
    >
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <DatePicker
          selectedYear={selected.year}
          selectedMonth={selected.month}
          onChangeMonth={(month) => { setSelected({ ...selected, month }); setErrMsg('') }}
          onChangeYear={(year) => { setSelected({ ...selected, year }); setErrMsg('') }}
        />
        <p style={{ margin: 25, color: 'red' }}>
          {errMsg.message}
        </p>

      </div>

    </Modal>
  )
}

export default ConfirmationModal
