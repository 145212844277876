import React, { useState, useEffect, useContext } from 'react'
import styles from './isrFileHistory.module.scss'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import FileHistoryHeader from '../../../../components/molecules/FileHistoryHeader/FileHistoryHeader'
import { NotifierContext } from '../../../../context/NotifierContext'
import { getUploadStatus, getDownLoadProcessedResultUrl } from '../../../../services/api/tcAuditService'
import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'
import dayjs from 'dayjs'
import AuditMonthStickyHeader from '../../../../components/atoms/AuditMonthStickyHeader/AuditMonthStickyHeader'
import InfoModal from '../../../../components/atoms/Modal/Modal'
import { fetchUserDesignation } from '../../../../services/utils/meta'
import Filter from '../../../Shared/Filter/Filter'
import { getFileHistoryFilterOptions } from '../../../../assets/offlineData/fileHistoryFilterOptions'
import AuditMonthFilter from '../../../Shared/Filter/AuditMonthFilter'
import { DateToYYYYMM } from '../../../../services/utils/date'

const IsrFileHistory = ({ selectedFile }) => {
  const [loading, setLoading] = useState(true)
  const [buttonLoader, setButtonLoader] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const [filesHistory, setFilesHistory] = useState([])
  const [modalVisible, setModalVisibility] = useState(false)
  const [isFilterVisible, setFilterVisibility] = useState(false)
  const [filters, setFilters] = useState({
    uploadedBy: '',
    fileStatus: '',
    auditMonth: undefined
  })
  const [pageData, setPageData] = useState({
    current: 1,
    last: 1
  })
  const { notifyError } = useContext(NotifierContext)
  const loggedInUserDesignation = fetchUserDesignation()

  useEffect(() => {
    fetchFileUploadStatus(1, filters.uploadedBy, filters.fileStatus, filters.auditMonth)
  }, [])

  const setFileStatusFilter = (selectedValue) => {
    setFilters(prevValue => ({
      ...prevValue,
      fileStatus: prevValue.fileStatus === selectedValue ? '' : selectedValue
    }))
  }

  const setUploadedByFilter = (selectedValue) => {
    setFilters(prevValue => ({
      ...prevValue,
      uploadedBy: selectedValue
    }))
  }

  const setAuditMonthFilter = (selectedValue) => {
    setFilters(prevValue => ({
      ...prevValue,
      auditMonth: selectedValue
    }))
  }

  const handleFilterRemoval = (filterName, filterValue) => {
    if (filterName === 'fileStatus') {
      setFileStatusFilter('')
      fetchFileUploadStatus(1, filters.uploadedBy, '', filters.auditMonth)
    }
    if (filterName === 'uploadedBy') {
      setUploadedByFilter('')
      fetchFileUploadStatus(1, '', filters.fileStatus, filters.auditMonth)
    }
    if (filterName === 'auditMonth') {
      setAuditMonthFilter(undefined)
      fetchFileUploadStatus(1, filters.uploadedBy, filters.fileStatus, undefined)
    }
  }

  const filterOptions = getFileHistoryFilterOptions(filters.fileStatus, setFileStatusFilter, filters.uploadedBy, setUploadedByFilter)

  const fetchFileUploadStatus = async (page, uploadedBy, fileStatus, auditMonth) => {
    try {
      page === 1 && setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'isr',
        page,
        perPage: 10,
        sortBy: 'auditAndUploadDate',
        ...(uploadedBy !== '' && { uploadedBy }),
        ...(fileStatus !== '' && { fileStatus }),
        ...(auditMonth && { month: DateToYYYYMM(auditMonth) })
      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
      setButtonLoader(false)
    }
  }

  const handleLoadMore = () => {
    const nextPage = pageData.current + 1
    setButtonLoader(true)
    fetchFileUploadStatus(nextPage, filters.uploadedBy, filters.fileStatus, filters.auditMonth)
  }

  const handleProcessingResultDownload = async (fileId) => {
    try {
      setProcessedResultLoader(fileId)
      const response = await getDownLoadProcessedResultUrl('isr', fileId)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  const categorizedFileHistory = filesHistory.reduce((accumulator, item) => {
    const monthName = dayjs(item.month).format('MMMM YYYY') // Format the date to month name
    if (!accumulator[monthName]) {
      accumulator[monthName] = []
    }
    accumulator[monthName].push(item)
    return accumulator
  }, {})

  const handleFilterApply = () => {
    fetchFileUploadStatus(1, filters.uploadedBy, filters.fileStatus, filters.auditMonth)
    setFilterVisibility(false)
  }

  const handleFilterReset = () => {
    fetchFileUploadStatus(1, '', '', undefined)
    setFilters({ uploadedBy: '', fileStatus: '', auditMonth: undefined })
    setFilterVisibility(false)
  }

  return (
    <div className='container'>
      <FileHistoryHeader
        title={selectedFile.name}
        onRefresh={() => fetchFileUploadStatus(1, filters.uploadedBy, filters.fileStatus, filters.auditMonth)}
        loading={loading}
        onClickFilter={() => setFilterVisibility(true)}
        filtersApplied={filters}
        onFilterRemoval={handleFilterRemoval}
      />
      <div className='mt-3'>
        {loading
          ? <div>Loading....</div>
          : filesHistory.length === 0
            ? <div>No results found!</div>
            : (
              <>
                {
                  Object.keys(categorizedFileHistory).map(monthYear => (
                    <div key={monthYear}>
                      <AuditMonthStickyHeader monthYear={monthYear} />
                      {categorizedFileHistory[monthYear].map((file, index) => {
                        return (
                          <div className={styles.fileStatusContainer} key={file.fileId}>
                            <FileStatus
                              uploadedBy={file.uploadedBy}
                              index={index + 1}
                              sectionResult={file.sectionResult}
                              status={file.status}
                              fileId={file.fileId}
                              uploadedOn={file.uploadedOn}
                              message={file.message}
                              onProcessingResultDownload={() => { handleProcessingResultDownload(file.fileId) }}
                              disableProcessedResult={processedResultLoader === file.fileId}
                              count={{
                                success: file.count?.success ?? '-',
                                failure: file.count?.failure ?? '-',
                                skipped: file.count?.skipped ?? '-'
                              }}
                              onClickFailureRows={() => setModalVisibility('failure')}
                              onClickSkippedRows={() => setModalVisibility('skipped')}
                              displaySectionResultButton={loggedInUserDesignation === 'cio'}
                            />
                          </div>
                        )
                      })}
                    </div>))
                  }

                {!(pageData.current === pageData.last) &&
                  <div className={styles.loadMoreBtnContainer}>
                    <LoadMoreButton onClick={handleLoadMore} loading={buttonLoader} />
                  </div>}
              </>
              )}

      </div>
      <InfoModal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'failure' &&
          (
            <>
              1. When MR provided doesn’t exist
              <br /><br />

            </>
          )}
        {modalVisible === 'skipped' &&
          <>
            1. Rows are skipped when their DOS (Date of service) is newer than audit month (future installation)
            <br /> <br />
            2. Rows are skipped when the installation is already present in the O&M and there are no changes in either the reading day or the reading MR
            <br /> <br />
            3. Rows with INACTIVE status are skipped
            <br /> <br />
          </>}
      </InfoModal>
      <Filter
        isFilterVisible={isFilterVisible}
        onFilterClose={() => { setFilterVisibility(false) }}
        onFiltersApply={handleFilterApply}
        onResetApply={handleFilterReset}
        filterData={filterOptions}
        customUI={
          <AuditMonthFilter
            selectedMonth={filters.auditMonth}
            onChangeMonth={setAuditMonthFilter}
          />
        }
      />
    </div>
  )
}

export default IsrFileHistory
