import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import EditIcon from '../../assets/img/edit-selected-icon-blue.png'
import { fetchAEProfileDetails } from '../../services/api/profileService'
import { NotifierContext } from '../../context/NotifierContext'
import FullScreenLoader from '../../components/atoms/FullScreenLoader'
import { fetchUserDesignation } from '../../services/utils/meta'
import { hierarchyDetails } from '../../assets/offlineData/hierarchyData'

export default function ProfileView () {
  const notifier = useContext(NotifierContext)

  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    sectionName: '',
    sectionCode: '',
    designation: '',
    contactNumber: ''
  })

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchAEProfile()
  }, [])

  const userDesignation = fetchUserDesignation()
  const {
    location: { areaName, areaCode },
    area
  } = hierarchyDetails.find((hierarchy) => hierarchy.designation === userDesignation)

  const fetchAEProfile = async () => {
    try {
      const response = await fetchAEProfileDetails()
      const { name, location, phoneNumber, userType } = response?.data
      setProfile({
        ...profile,
        firstName: name?.firstName,
        lastName: name?.lastName,
        sectionName: location?.name?.[areaName],
        sectionCode: location?.[areaCode],
        designation: userType,
        contactNumber: phoneNumber
      })
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const { firstName, lastName, sectionName, sectionCode, designation, contactNumber } =
    profile

  return (
    <>
      <div className='profile-content'>
        <div className='container'>
          <div className='row align-items-center mb-3'>
            <div className='col-12 col-md-6'>
              <h1 className='mb-0'>My Profile</h1>
            </div>
            <div className='col-12 col-md-6 text-right'>
              <Link
                to={{
                  pathname: '/profile/edit',
                  state: { profile }
                }}
                type='submit'
                className='btn btn-primary ghost-btn'
              >
                <img
                  className='img-fluid icon-width-height'
                  src={EditIcon}
                  alt='Edit Icon'
                />
                Edit
              </Link>
            </div>
          </div>
          {loading
            ? (
              <FullScreenLoader />
              )
            : (
              <div className='row'>
                <div className='col-md-12'>
                  <div className='list-view-box mt-2'>
                    <h3 className='mb-4'>Personal Details</h3>
                    <div className='profile-form'>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>First Name</label>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                          <p className='label-value'>{firstName ?? '----'}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Last Name</label>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                          <p className='label-value'>{lastName || '----'}</p>
                        </div>
                      </div>
                      {userDesignation !== 'dt' && userDesignation !== 'cio' && (
                        <>
                          <div className='row'>
                            <div className='col-12 col-md-4 mb-4'>
                              <label className='form-label font-medium'>{area} Name</label>
                            </div>
                            <div className='col-12 col-md-4 mb-4'>
                              <p className='label-value'>{sectionName ?? '----'}</p>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-12 col-md-4 mb-4'>
                              <label className='form-label font-medium'>{area} Code</label>
                            </div>
                            <div className='col-12 col-md-4 mb-4'>
                              <p className='label-value'>{sectionCode ?? '----'}</p>
                            </div>
                          </div>
                        </>
                      )}
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Designation</label>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                          <p className='label-value'>{designation ?? '----'}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Contact Number</label>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                          <p className='label-value'>{contactNumber ?? '----'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )}
        </div>
      </div>
    </>
  )
}
