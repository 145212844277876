import dashboard from '../../assets/img/dashboard-icon.svg'
import mrIcon from '../../assets/img/mr-management-selected-icon.svg'
import feederIcon from '../../assets/img/mr-management-icon.svg'
import reportsIcon from '../../assets/img/reports.png'
import tcDetailsIcon from '../../assets/img/tc-audit-default-icon.svg'
import billing from '../../assets/img/group-4773.png'
import guide from '../../assets/img/guide.png'
import fileUploadIcon from '../../assets/img/file-upload.png'
import auditResult from '../../assets/img/audit-result.png'
import { fetchUserDesignation } from '../../services/utils/meta'

export const SidebarData = () => {
  const loggedInUserDesignation = fetchUserDesignation()

  return [
    {
      title: 'Dashboard',
      icon: dashboard,
      link: '/dashboard',
      disabled: false
    },
    {
      title: 'MR Management',
      icon: mrIcon,
      link: '/mr-management',
      disabled: false
    },
    {
      title: 'Feeder Management',
      icon: feederIcon,
      link: '/feeders',
      disabled: false
    },
    ...((loggedInUserDesignation === 'ae' || loggedInUserDesignation === 'cio')
      ? [{
          title: 'File upload',
          icon: fileUploadIcon,
          link: loggedInUserDesignation === 'ae' ? '/file-upload?tab=mrOnboarding' : '/file-upload?tab=userOnboarding',
          disabled: false
        }]
      : []),
    {
      title: 'Audit Result',
      icon: auditResult,
      link: '/tc-audit',
      disabled: false

    },
    {
      title: 'TC Details',
      icon: tcDetailsIcon,
      link: '/tc-details',
      disabled: false
    },
    {
      title: 'Billing',
      icon: billing,
      link: '/billing',
      disabled: false
    },
    {
      title: 'Guide',
      icon: guide,
      link: '/billing',
      disabled: false
    },
    ...((loggedInUserDesignation === 'cio')
      ? [{
          title: 'Reports',
          icon: reportsIcon,
          link: '/reports',
          disabled: false
        }]
      : [])
  ]
}
