import React, { useState, useEffect, useContext } from 'react'
import { getReportList, getReportPresignedUrl } from '../../services/api/miscService'
import NoDataFound from '../../components/atoms/NoDataFound'
import downloadIcon from '../../assets/img/file_download-24px.png'
import { Colors } from '../../theme/colors'
import { NotifierContext } from '../../context/NotifierContext'
import dayjs from 'dayjs'

const Reports = () => {
  const [loading, setLoading] = useState(true)
  const [reportList, setReportList] = useState([])
  const [reportDownloadLoader, setReportDownloadLoader] = useState(false)
  const { notifyError } = useContext(NotifierContext)

  useEffect(() => {
    fetchReportList()
  }, [])

  const fetchReportList = async () => {
    try {
      const response = await getReportList()
      setReportList(response.data.reportsList)
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleReportDownload = async (reportId, reportIndex) => {
    try {
      setReportDownloadLoader(reportIndex)
      const response = await getReportPresignedUrl(reportId)
      window.location.href = response.data
    } catch (err) {
      notifyError(err)
    } finally {
      setReportDownloadLoader(false)
    }
  }

  return (
    <>
      <div className='management-content'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6 '>
              <h1 className='mb-3'>Reports</h1>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <div className='main-management-table list-view-box mt-2'>
                <div className='pt-0 pb-2 d-flex flex-1 align-items-center justify-content-space-between'>
                  <h3 className='ms-3 font-medium-500'>
                    Download Reports
                  </h3>
                </div>

                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th
                          className='pl-15'
                          scope='col'
                        >
                          #
                        </th>
                        <th scope='col'>Report Name</th>
                        <th scope='col'>Last updated on</th>
                        <th scope='col'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportList.map((report, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className='pl-15'
                              scope='row'
                            >
                              {index + 1}
                            </td>
                            <td>{report.reportName}</td>
                            <td
                              className='pl-15'
                              scope='row'
                            >
                              {dayjs(report.lastModified).format('DD/MM/YYYY, hh:mm a') ?? '----'}
                            </td>
                            <td>
                              <div
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  pointerEvents: reportDownloadLoader === index ? 'none' : 'auto',
                                  opacity: reportDownloadLoader === index ? 0.4 : 1,
                                  color: Colors.secondaryColor,
                                  width: 200
                                }}
                                onClick={() => handleReportDownload(report.reportId, index)}
                              >
                                {reportDownloadLoader === index ? 'Downloading...' : 'Download'}
                                <img
                                  className='img-fluid download-icon' src={downloadIcon} alt='missing icon'
                                  style={{
                                    filter: 'brightness(0) saturate(100%) invert(40%) sepia(15%) saturate(2466%) hue-rotate(182deg) brightness(96%) contrast(94%)'
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                      {reportList.length === 0 && <NoDataFound loading={loading} />}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reports
