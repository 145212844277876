export const aampleFiles = [
  'MR onboarding',
  'User onboarding',
  'Power stations and feeders',
  'Create/Edit TC',
  'TC installation tagging',
  'TC reading'
]

export const rapdrpFiles = [
  'Installation status report',
  'Unbilled report',
  'MNR report',
  'Vacant report',
  'Doorlock report',
  'Zero consumption report',
  'Abnormal consumption report',
  'Subnormal consumption report',
  'Consumption DCB report',
  'Revenue DCB report'
]
