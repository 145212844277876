import React, { useContext, useMemo, useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import BillingDetailsChart from './BillingDetailsChart'
// import CollectionDetailsChart from './CollectionDetailsChart'
import DTCEnergyAuditChart from './DTCEnergyAuditChart'
import styles from './chart.module.scss'
import { NotifierContext } from '../../context/NotifierContext'
import { getBillingCount } from '../../services/api/billingService'
import { DateToYYYYMM } from '../../services/utils/date'
import { getAuditMetrics } from '../../services/api/tcAuditService'
import { HierarchyContext } from '../../context/HierarchyContext'

const Index = (props) => {
  const notifier = useContext(NotifierContext)

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const [startDate, setStartDate] = useState(new Date())
  const [initialRender, setInitialRender] = useState(true)
  const [selectedBDMonth, setSelectedBDMonth] = useState(monthNames[new Date().getMonth()])
  const [selectedDTCMonth, setSelectedDTCMonth] = useState(monthNames[new Date().getMonth()])
  const [auditMetricsLoader, setAuditMetricsLoader] = useState(true)
  const [billingLoader, setBillingLoader] = useState(true)

  const [billingData, setBillingData] = useState({
    all: '',
    doorLock: '',
    unbilled: '',
    vacant: '',
    mnr: '',
    zeroConsumption: ''
  })
  const [DTCData, setDTCData] = useState({})

  const { selectedHierarchy } = useContext(HierarchyContext)

  const categories = ['all', 'unbilled', 'vacant', 'doorLock', 'mnr', 'zeroConsumption']

  useEffect(() => {
    setInitialRender(false)
    categories.forEach(item => {
      fetchBillingData(item)
    })
    fetchAuditMetrics()
  }, [startDate, selectedHierarchy])

  useEffect(() => {
    if (!initialRender) {
      categories.forEach(item => {
        fetchBillingData(item)
      })
    }
  }, [selectedBDMonth])

  useEffect(() => {
    if (!initialRender) {
      fetchAuditMetrics()
    }
  }, [selectedDTCMonth])

  // Fetch Data for Billing Details Chart
  const fetchBillingData = async (category) => {
    try {
      setBillingLoader(true)
      const response = await getBillingCount(
        DateToYYYYMM(new Date(startDate.getFullYear(), monthNames.indexOf(selectedBDMonth))),
        selectedHierarchy,
        category
      )
      setBillingData(prevState => ({
        ...prevState,
        [category]: response.data
      }))
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setBillingLoader(false)
    }
  }

  // Fetch Data for DTC Energy Audit Chart
  const fetchAuditMetrics = async () => {
    try {
      setAuditMetricsLoader(true)
      const response = await getAuditMetrics(
        DateToYYYYMM(new Date(startDate.getFullYear(), monthNames.indexOf(selectedDTCMonth))),
        selectedHierarchy
      )
      const {
        negativeLoss,
        zeroToFive,
        fiveToTen,
        tenToFifteen,
        fifteenToTwenty,
        twentyAndAbove,
        totalTransformers,
        audited,
        unAudited
      } = response.data
      setDTCData({
        negativeLoss,
        zeroToFive,
        fiveToTen,
        tenToFifteen,
        fifteenToTwenty,
        twentyAndAbove,
        totalTransformers,
        audited,
        unAudited
      })
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setAuditMetricsLoader(false)
    }
  }

  /// /////////////////MEMOIZING Charts///////////////////////////////

  const BillingDetails = useMemo(() => {
    const { all, doorLock, unbilled, vacant, mnr, zeroConsumption } = billingData
    return <BillingDetailsChart data={[all, doorLock, unbilled, vacant, mnr, zeroConsumption]} />
  }, [billingData])

  const DTCEnergyAudit = useMemo(() => {
    return (
      <DTCEnergyAuditChart
        totalTCs={DTCData.totalTransformers}
        audited={DTCData.audited}
        unaudited={DTCData.unAudited}
      />
    )
  }, [DTCData])

  return (
    <div className='dashboard-content ' style={{ height: '92vh', display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
      <div className='' style={{ height: '92vh', display: 'flex', flexDirection: 'column', flex: 1, padding: 30 }}>
        <div className='row mt-2 dashboard-charts' style={{ height: '100%' }}>
          <div className='col-md-6 mb-4 card-group'>
            <div className='card'>
              <div className='card-body charts'>
                <div className='top-heading'>
                  <h5 className='card-subtitle roboto'>Billing Details</h5>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showYearPicker
                    dateFormat='yyyy'
                    maxDate={new Date()}

                  />
                </div>
                <div className='contain'>{billingLoader
                  ? <div className={styles.chartLoading}>Loading...</div>
                  : BillingDetails}
                </div>
                <div className={`mt-3 year-list ${styles.monthSelector}`}>
                  {monthNames.map((item) => (
                    <span
                      key={item}
                      onClick={() => setSelectedBDMonth(item)}
                      className={`roboto font-medium-500 ${styles.month} ${
                          selectedBDMonth === item ? styles.activeMonth : ''
                        }`}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 mb-4 card-group'>
            <div className='card'>
              <div className='card-body charts'>
                <div className='top-heading'>
                  <h5 className='card-subtitle roboto'>DTC Energy Audit</h5>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showYearPicker
                    dateFormat='yyyy'
                    maxDate={new Date()}

                  />
                </div>
                {/* <div style={{ display: 'flex' }}> */}
                {/* <div style={{ background: 'pink', display: 'flex', flex: 1 }}> */}
                <div className='contain'>{auditMetricsLoader
                  ? <div className={styles.chartLoading}>Loading...</div>
                  : DTCEnergyAudit}
                </div>
                {/* </div> */}
                {/* <div
                    style={{ width: '30%' }}
                    className='dashboard-negative-loss'
                  >
                    <ul>
                      <li>
                        <span style={{ width: '50%' }}>Negative Loses</span>
                        <span style={{ width: '40%' }}>{DTCData.negativeLoss}</span>
                      </li>
                      <li>
                        <span>0 - 5%</span>
                        <span>{DTCData.zeroToFive}</span>
                      </li>
                      <li>
                        <span>5 - 10%</span>
                        <span>{DTCData.fiveToTen}</span>
                      </li>
                      <li>
                        <span>10 - 20%</span>
                        <span>{DTCData.tenToFifteen}</span>
                      </li>
                      <li>
                        <span>{'>'} 20%</span>
                        <span>{DTCData.twentyAndAbove}</span>
                      </li>
                    </ul>
                  </div> */}
                {/* </div> */}
                <div className={`mt-3 year-list ${styles.monthSelector}`}>
                  {monthNames.map((item) => (
                    <span
                      key={item}
                      onClick={() => setSelectedDTCMonth(item)}
                      className={`roboto font-medium-500 ${styles.month} ${
                          selectedDTCMonth === item ? styles.activeMonth : ''
                        }`}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Index
