import React from 'react'

const SampleFileInstructions = () => {
  return (
    <>
      *  Indicates mandatory columns in the excel sheet<br /><br />
      <b>Instructions to be followed while filling these fields</b><br /><br />
      {/* <b>Section code*</b><br />
      1. The Section code column must contain section code and not section name<br /><br /> */}
      <b>TC Reading/ Remark*</b><br />
      1. TC readings have to be a valid number<br />
      2. In case there is no reading the file also accepts remarks<br />
      3. Allowed remark values - <b>METER_NOT_RECORDING, CT_BURNT_OUT, NO_DISPLAY, OTHERS, METER_NOT_PROVIDED</b><br />
    </>
  )
}

export default SampleFileInstructions
