import { API } from 'aws-amplify'
import { fetchUserDesignation } from '../utils/meta'

export const getGuidesPresignedUrl = () => {
  const loggedInUserDesignation = fetchUserDesignation()

  return API.get('baseURL', `/${loggedInUserDesignation}/guides`)
}

export const getReportPresignedUrl = (reportId) => {
  return API.get('baseURL', `/cio/files/reports/download/${reportId}`)
}

export const getReportList = () => {
  return API.get('baseURL', '/cio/files/reports/list')
}
