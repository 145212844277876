import React from 'react'
import './uploadSection.scss'
import UploadImage from '../../../assets/img/upload-icon@2x.png'
import fileIcon from '../../../assets/img/file-icon.svg'
import downloadIcon from '../../../assets/img/file_download-24px.png'
import DatePicker from 'react-datepicker'
import { aampleFiles, rapdrpFiles } from '../../../assets/offlineData/fileData'
import { fetchUserDesignation } from '../../../services/utils/meta'

const UploadSection = ({
  hasSampleFile = false,
  handleUpload,
  fileName,
  hasAuditMonth = true,
  sampleFileHandler,
  handleFileUpload,
  fileUploadDisabled = false,
  selectedDate,
  onChangeDate

}) => {
  const rapdrpFilesMaxDate = new Date()
  rapdrpFilesMaxDate.setMonth(rapdrpFilesMaxDate.getMonth() - 2)

  const loggedInUserDesignation = fetchUserDesignation()

  return (
    <div className='root'>
      <div className='left-col'>
        <div className='heading'>{fileName}</div>
        {hasSampleFile &&
          (
            <div className='small-text' onClick={sampleFileHandler}>
              <img
                className='img-fluid'
                src={fileIcon}
                alt='missing icon'
              />
              Sample file
              <img className='img-fluid download-icon' src={downloadIcon} alt='missing icon' />
            </div>
          )}

      </div>
      <div className='right-col'>
        {hasAuditMonth &&
          <DatePicker
            dateFormat='MMMM yyyy'
            showMonthYearPicker
            showFullMonthYearPicker
            showThreeColumnMonthYearPicker
            placeholderText='Select Audit Month'
            selected={selectedDate}
            onChange={(date) => onChangeDate(date)}
            // minDate={new Date(2024, 10, 30)}
            // maxDate={new Date(2024, 11, 31)}
            {...(aampleFiles.includes(fileName) && { maxDate: new Date() })}
            {...((fileName === 'Create/Edit TC' && loggedInUserDesignation !== 'cio') && { minDate: new Date(2024, 10, 30) })}
            {...(fileName === 'Create/Edit TC' && { maxDate: new Date(2024, 11, 31) })}
            {...(rapdrpFiles.includes(fileName) && { maxDate: rapdrpFilesMaxDate })}
            {...(loggedInUserDesignation === 'cio' && { maxDate: new Date() })}

          />}
        <button
          className='btn btn-primary custom-active custom-btn'
          id='file-upload'
          disabled={fileUploadDisabled}
        >
          Upload
          {'\t'}
          {fileUploadDisabled
            ? <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
            : <img className='img-fluid' src={UploadImage} alt='missing icon' />}
          <input
            for='file-upload'
            className='form-control'
            type='file'
            onChange={handleFileUpload}
            onClick={(event) => { event.target.value = '' }}
          />
        </button>
      </div>
    </div>
  )
}

export default UploadSection
