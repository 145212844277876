import { useEffect, useState } from 'react'

const DeviceCheck = ({ children }) => {
  const [isAllowed, setIsAllowed] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isMobile = /android|iphone|ipad|ipod|mobile/i.test(userAgent)
    const isTablet = /tablet|ipad/i.test(userAgent)
    const isSmallScreen = window.innerWidth < 1024

    if (isMobile || isTablet || isSmallScreen) {
      setIsAllowed(false)
      setLoading(false)
    } else {
      setIsAllowed(true)
      setLoading(false)
    }

    const handleResize = () => {
      setIsAllowed(window.innerWidth >= 1024)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!isAllowed) {
    if (loading) {
      return (
        <div style={{
          textAlign: 'center',
          padding: '50px',
          fontSize: '20px'
        }}
        >Loading...
        </div>
      )
    }
    return (
      <div
        style={{
          textAlign: 'center',
          padding: '50px',
          fontSize: '20px',
          color: 'red'
        }}
      >
        ❌ This application is not compatible with small screen device. Only accessible on Desktop, Laptop, or TV.
      </div>
    )
  }

  return children
}

export default DeviceCheck
