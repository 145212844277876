import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { SidebarData } from './SidebarData'
import downArrow from '../../assets/img/user-down-arrow.svg'
import upArrow from '../../assets/img/white-up-arrow.svg'
import styles from '../../assets/scss/constants.module.scss'
import { getGuidesPresignedUrl } from '../../services/api/miscService'
import { NotifierContext } from '../../context/NotifierContext'
// import { fetchUserDesignation } from '../../services/utils/meta'

const SubMenu = (props) => {
  return (
    <ul className={`${props.isShowing ? '' : 'd-none'}`}>
      {
        props.menu.map((submenu, index) => {
          return (
            <li key={index}>
              <NavLink key={index} activeClassName='submenu-menu' to={submenu.link}>
                {submenu.title}
              </NavLink>
            </li>
          )
        })
      }
    </ul>
  )
}

const Sidebar = (props) => {
  const SidebarContent = SidebarData()
  const SidebarStyle = {
    height: `calc(100vh - ${styles.headerHeight})`, // scroll-fix (prev change - height : 100%)
    width: '86px',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
  const SecondSidebarStyle = {
    height: `calc(100vh - ${styles.headerHeight})`,
    position: 'fixed',
    display: props.sideBarMinimized ? 'none' : 'block',
    overflowX: 'hidden',
    overflowY: 'auto'
  }

  const [match, setMatch] = useState('')
  const [loading, setLoading] = useState(false)
  const notifier = useContext(NotifierContext)

  const subMenuHandler = (event, menu) => {
    if (menu.hasSubmenu) event.preventDefault()
    setMatch(menu.title)
  }

  // const loggedInUserDesignation = fetchUserDesignation()

  const handleGuideClick = async (e) => {
    try {
      setLoading(true)
      const response = await getGuidesPresignedUrl()
      e.preventDefault() // Prevent React Router navigation
      window.open(response.data, '_blank')
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <aside className={`nav-vertical small-sidebar ${props.sideBarMinimized ? 'important' : ''}`} style={SidebarStyle}>
        <div className='nav-wrapper'>
          <div className='nav-inner-wrapper'>
            <ul>
              {
                SidebarContent.map((val, key) => {
                  // if (loggedInUserDesignation !== 'ae' && val.title === 'File upload') {
                  //   return ''
                  // }
                  return (
                    <div
                      key={key}
                      data-bs-toggle='tooltip' data-bs-placement='top' title={val.disabled}
                      style={{ cursor: val.disabled ? 'not-allowed' : 'auto' }}
                    >
                      <li>
                        {val.title === 'Guide'
                          ? (
                              loading
                                ? <div style={{ color: 'white' }}>Loading...</div>
                                : (
                                  <a disabled={val.disabled} className='nav-link' onClick={handleGuideClick} target='_blank' style={{ cursor: 'pointer' }}>
                                    <img src={val.icon} alt='sidebar icon' style={{ maxHeight: 26, maxWidth: 26 }} />
                                  </a>
                                  )
                            )
                          : (
                            <NavLink disabled={val.disabled} className='nav-link' to={val.link}>
                              <img src={val.icon} alt='sidebar icon' style={{ maxHeight: 26, maxWidth: 26 }} />
                            </NavLink>
                            )}
                      </li>
                    </div>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </aside>
      <aside className='nav-vertical big-sidebar' style={SecondSidebarStyle}>
        <div className='nav-wrapper'>
          <div className='nav-inner-wrapper'>
            <ul>
              {
                SidebarContent.map((menu, key) => {
                  // if (loggedInUserDesignation !== 'ae' && menu.title === 'File upload') {
                  //   return ''
                  // }
                  return (
                    <div
                      key={key}
                      data-bs-toggle='tooltip' data-bs-placement='top' title={menu.disabled}
                      style={{ cursor: menu.disabled ? 'not-allowed' : 'auto' }}
                    >
                      <li>
                        {menu.title === 'Guide'
                          ? (loading
                              ? <div style={{ color: 'white', textAlign: 'center' }}>Loading...</div>
                              : (
                                <a disabled={menu.disabled} onClick={handleGuideClick} target='_blank' style={{ cursor: 'pointer' }}>
                                  <img src={menu.icon} alt='sidebar icon' style={{ maxHeight: 26, maxWidth: 26 }} />
                                  <span className='roboto font-regular-400'>{menu.title}</span>
                                  {menu.hasSubmenu && match !== menu.title && <img className='fa img-fluid' alt='sidebar icon' src={downArrow} />}
                                  {menu.hasSubmenu && match === menu.title && <img className='img-fluid fa' alt='sidebar icon' src={upArrow} />}
                                </a>
                                )
                            )
                          : (
                            <NavLink disabled={menu.disabled} onClick={(e) => subMenuHandler(e, menu)} to={menu.link}>
                              <img src={menu.icon} alt='sidebar icon' style={{ maxHeight: 26, maxWidth: 26 }} />
                              <span className='roboto font-regular-400'>{menu.title}</span>
                              {menu.hasSubmenu && match !== menu.title && <img className='fa img-fluid' alt='sidebar icon' src={downArrow} />}
                              {menu.hasSubmenu && match === menu.title && <img className='img-fluid fa' alt='sidebar icon' src={upArrow} />}
                            </NavLink>
                            )}
                        {
                        menu.hasSubmenu && <SubMenu isShowing={match === menu.title} menu={menu.subMenu} />
                      }
                      </li>
                    </div>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
