import React, { useState, useContext, useEffect } from 'react'
import UploadSection from '../../../../components/molecules/UploadSection/UploadSection'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import Button from '../../../../components/atoms/Button'
import styles from './TcReading.module.scss'
import historyIcon from '../../../../assets/img/history.png'
import SampleFileModal from '../../../../components/atoms/SampleFileModal/SampleFileModal'
import { NotifierContext } from '../../../../context/NotifierContext'
import SampleFileInstructions from './SampleFileInstructions'
import { getPresignedURL, uploadFile, getSampleFilePresignedURL, getUploadStatus, getDownLoadProcessedResultUrl } from '../../../../services/api/tcAuditService'
import { DateToYYYYMM } from '../../../../services/utils/date'
import { useHistory } from 'react-router-dom'
import RefreshButton from '../../../../components/atoms/RefreshButton/RefreshButton'
import InfoModal from '../../../../components/atoms/Modal/Modal'
import { fetchUserDesignation } from '../../../../services/utils/meta'
import SectionWiseResults from '../../../../components/molecules/SectionWiseResults/SectionWiseResults'

// import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'

const TcReading = ({ selectedFile }) => {
  const [sampleFileOpen, setSampleFileOpen] = useState(false)
  const [sampleFileLoader, setSampleFileLoader] = useState(false)
  const { notifyError, notifySuccess } = useContext(NotifierContext)
  const [filUploadLoader, setFileUploadLoader] = useState(false)
  const [auditMonth, setAuditMonth] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const [filesHistory, setFilesHistory] = useState([])
  const [modalVisible, setModalVisibility] = useState(false)
  // const [pageData, setPageData] = useState({
  //   current: 1,
  //   last: 1
  // })

  const history = useHistory()

  useEffect(() => {
    fetchFileUploadStatus(1)
  }, [])

  const loggedInUserDesignation = fetchUserDesignation()

  const handleSampleFileDownload = async () => {
    try {
      setSampleFileLoader(true)
      const response = await getSampleFilePresignedURL('tcReadings')
      setSampleFileOpen(false)
      notifySuccess('File download has started...')
      window.location.href = response.data
    } catch (err) {
      notifyError(err)
    } finally {
      setSampleFileLoader(false)
    }
  }

  const handleFileUploading = async (event) => {
    try {
      setFileUploadLoader(true)
      if (auditMonth === undefined) {
        throw new Error('Select the audit month for which you want to upload this file!')
      }

      const fileExtension = event.target.files[0].name.split('.').pop()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xlsb' && fileExtension !== 'xls') {
        throw new Error('This type of file is not allowed to upload. You can upload only files which have xls,xlsx,xlsb extensions')
      }

      const auditMonthYear = DateToYYYYMM(auditMonth)
      const response = await getPresignedURL('tcReadings', auditMonthYear)
      await uploadFile(event.target.files[0], response.data[fileExtension])
      notifySuccess('Successfully Uploaded the TC Reading file.')
      setAuditMonth(undefined)
    } catch (err) {
      notifyError(err)
    } finally {
      setFileUploadLoader(false)
    }
  }

  const fetchFileUploadStatus = async (page) => {
    try {
      setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'tcReadings',
        page,
        perPage: 1,
        sortBy: 'uploadDate',
        ...(loggedInUserDesignation === 'ae' && { uploadedBy: 'AE' })

      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      // setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  // const handleLoadMore = () => {
  //   const nextPage = pageData.current + 1
  //   fetchFileUploadStatus(nextPage)
  // }

  const handleProcessingResultDownload = async (fileId, failureFile) => {
    try {
      setProcessedResultLoader(fileId)
      const response = await getDownLoadProcessedResultUrl('tcReadings', fileId, failureFile)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  return (
    <div className='container'>
      <UploadSection
        fileName={selectedFile.name}
        hasSampleFile
        hasAuditMonth
        sampleFileHandler={() => setSampleFileOpen(true)}
        handleFileUpload={handleFileUploading}
        fileUploadDisabled={filUploadLoader}
        selectedDate={auditMonth}
        onChangeDate={setAuditMonth}

      />

      <div className='d-flex align-items-center justify-content-between mt-5 my-4'>
        <div className={styles.heading}>
          Latest file upload status (uploaded by me)
        </div>
        <RefreshButton onRefresh={() => { fetchFileUploadStatus(1) }} loading={loading} />

      </div>
      {loading
        ? <div>Loading....</div>
        : filesHistory.length === 0
          ? <div>No results found!</div>
          : (
            <>
              {filesHistory.map((file, index) => {
                return (
                  <div className={styles.fileStatusContainer} key={file.fileId}>
                    <FileStatus
                      index={index + 1}
                      sectionResult={file.sectionResult}
                      status={file.status}
                      hasErrorResult
                      fileId={file.fileId}
                      uploadedOn={file.uploadedOn}
                      message={file.message}
                      onProcessingResultDownload={(failureFile) => { handleProcessingResultDownload(file.fileId, failureFile) }}
                      disableProcessedResult={processedResultLoader === file.fileId}
                      count={{
                        success: file.count?.success ?? '-',
                        failure: file.count?.failure ?? '-',
                        skipped: file.count?.skipped ?? '-'
                      }}
                      onClickFailureRows={() => setModalVisibility('failure')}
                      onClickSkippedRows={() => setModalVisibility('skipped')}
                      displaySectionResultButton={loggedInUserDesignation === 'cio'}
                    />
                  </div>
                )
              })}
              {/* {!(pageData.current === pageData.last) &&
                <LoadMoreButton onClick={handleLoadMore} />} */}
            </>
            )}
      <div className={`${styles['btn-container']} mt-5`}>
        <Button
          className={`btn btn-light ${styles['upload-history-btn']}`}
          onClick={() => {
            history.push('/file-upload?tab=tcReadingFileHistory')
          }}
        >
          View file upload history
          <img src={historyIcon} alt={styles['history-icon']} />
        </Button>
      </div>
      <SectionWiseResults
        onClickFailureRows={() => setModalVisibility('failure')}
        onClickSkippedRows={() => setModalVisibility('skipped')}
      />
      <SampleFileModal
        heading='Instructions - TC Reading'
        okBtnDisabled={sampleFileLoader}
        open={sampleFileOpen}
        onClose={() => setSampleFileOpen(false)}
        onSubmit={handleSampleFileDownload}
      >
        <SampleFileInstructions />
      </SampleFileModal>
      <InfoModal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'failure' &&
          (
            <>
              1. When TC could not be found in the given O&M<br />
              2. When reading is less than zero<br />
              3. When reading is greater than 9999999999<br />
            </>
          )}
        {modalVisible === 'skipped' &&
          <>
            1. Rows are skipped when there are duplicate rows i.e., when a TC with given number for a given section already exists<br />
            2. Rows are skipped if that section is ineligible
          </>}
      </InfoModal>
    </div>
  )
}

export default TcReading
