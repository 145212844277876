import React, { Fragment, useState, useEffect } from 'react'
import closeIcon from '../../../assets/img/close-icon.png'
import 'react-datepicker/dist/react-datepicker.css'

const ViewTC = (props) => {
  const [activeScreen] = useState('add_tc_form')

  const [state, setState] = useState({
    taggedMRs: [],
    readingMR: '',
    readingDay: '',
    tcName: '',
    tcNumber: '',
    tcSerialNumber: '',
    tcMake: '',
    tcCapacity: '',
    timsCode: '',
    dtlmsCode: '',
    dtrCode: '',
    gpsCoordinates: {
      latitude: '',
      longitude: ''
    },
    executionType: '',
    feederId: '',
    meterMake: '',
    meterSerialNumber: '',
    primaryCurrent: '',
    secondaryCurrent: '',
    meterConstant: '',
    initialReadingMonth: '',
    initialReading: '',
    remarks: '',
    feederNumber: '' // not to be considered, only to display feeder while editing
  })

  useEffect(() => {
    if (
      !state.secondaryCurrent ||
      !state.primaryCurrent ||
      state.primaryCurrent === 0 ||
      state.secondaryCurrent === 0
    ) {
      setState({ ...state, meterConstant: '' })
      return
    }
    setState({
      ...state,
      meterConstant: String(
        Math.round((state.primaryCurrent / state.secondaryCurrent) * 100) / 100
      )
    })
  }, [state.secondaryCurrent, state.primaryCurrent])

  useEffect(() => {
  }, [])

  useEffect(() => {
    const { tcData } = props
    // if (state.tcNumber !== tcData?.number) {
    const taggedMRs = tcData.taggedMRs.map((item) => {
      return item?.mrCode ?? 'N.A'
    })

    const stateValues = {
      ...state,
      taggedMRs,
      readingMR: tcData?.readingMR?.mrCode ?? '',
      tcNumber: tcData?.number ?? '',
      tcName: tcData?.name ?? '',
      readingDay: String(tcData?.readingDay) ?? '',
      tcSerialNumber: String(tcData?.serialNumber ?? ''),
      tcMake: tcData?.make ?? '',
      tcCapacity: tcData?.capacity ?? '',
      timsCode: String(tcData?.timsCode ?? ''),
      dtlmsCode: tcData?.dtlmsCode ?? '',
      dtrCode: tcData?.dtrCode ?? '',
      gpsCoordinates: {
        latitude: tcData?.gpsCoordinates?.coordinates?.lat ?? '',
        longitude: tcData?.gpsCoordinates?.coordinates?.long ?? ''
      },
      executionType: tcData?.executionType ?? '',
      meterMake: tcData?.meter?.make,
      meterSerialNumber: String(tcData?.meter?.serialNumber ?? ''),
      primaryCurrent: tcData?.meter?.ctRatio?.primaryCurrent,
      secondaryCurrent: tcData?.meter?.ctRatio?.secondaryCurrent,
      feederNumber: tcData?.feeder?.number
        ? `${tcData?.feeder?.number}, ${tcData?.feeder?.name}`
        : '',
      feederId: tcData?.feeder?.id ?? ''
    }
    setState({ ...stateValues })
    // }
  }, [props.tcData])

  const onCloseButtonHandler = (event) => {
    props.onCloseButton()
  }

  return (
    <>

      <div className='row add-tc-info'>
        <div className='col-md-12 mb-3 mt-3'>
          <div className='code-section'>
            <span>Energy Audit &nbsp;|</span>
            <span className='add-new-tc-text'>
              <a>&nbsp; View TC Details</a>
            </span>
          </div>
          <hr className='horizontal-line' />
        </div>
      </div>

      <div className='row add-tc-info'>
        <div className='col-md-6'>
          <div className='add-tc-title'>
            <h1 className='mb-0'>TC no.  {props?.tcData?.number ?? ''}</h1>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='close-mr'>
            <button
              onClick={onCloseButtonHandler}
              type='button'
              className='btn btn-primary'
            >
              <img src={closeIcon} alt='missing icon' />
              Close
            </button>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 mt-4'>
          {activeScreen === 'add_tc_form' && (
            <div className='transformer-detail-form p-3 mb-3'>
              <div className='forms-input'>
                <div className='row custom-row'>
                  <div className='col-md-12 mb-3 mt-2'>
                    <div className='x-former-detail'>
                      <h3 className='mb-2'>Transformer Details</h3>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Tagged MRs</label>
                    {state.taggedMRs.map(item => {
                      return (
                        <div key={item}>
                          {item}
                        </div>
                      )
                    })}
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Reading MR</label>
                    <div>
                      {state.readingMR}
                    </div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Number</label>
                    <div>{state.tcNumber}</div>

                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Name</label>
                    <div>{state.tcName}</div>
                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Reading Day</label>
                    <div>{state.readingDay}</div>
                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Serial Number</label>
                    <div>{state.tcSerialNumber}</div>
                  </div>

                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Make</label>
                    <div>{state.tcMake}</div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TC Capacity</label>
                    <div>{state.tcCapacity}</div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>TIMS Code</label>
                    <div>{state.timsCode}</div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>DTLMS</label>
                    <div>{state.dtlmsCode}</div>

                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>DTR</label>
                    <div>{state.dtrCode}</div>
                  </div>
                  <div className='col-md-4 mb-4 position-relative'>
                    <label className='form-label font-regular-400'>GPS Location</label>
                    <div>Lat :{state?.gpsCoordinates?.latitude ?? '--'}</div>
                    <div>Long: {state?.gpsCoordinates?.longitude ?? '--'}</div>

                  </div>
                  <div className='col-md-4 mb-4 position-relative feeder-name-select'>
                    <label className='form-label font-regular-400'>Execution Type</label>
                    <div>{state.executionType}</div>
                  </div>
                  <div className='col-md-4 mb-4 position-relative'>
                    <label className='form-label font-regular-400'>Tagged Feeder</label>
                    <div>{state.feederNumber}</div>
                  </div>
                  <hr className='divider' />
                  {/* /////////////////////////////////////////////METER DETAILS////////////////////////////////// */}
                  <div className='col-md-12 mb-3 mt-2'>
                    <div className='x-former-detail'>
                      <h3 className='mb-2'>Meter Details</h3>
                    </div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Make</label>
                    <div>{state.meterMake}</div>
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label font-regular-400'>Serial Number</label>
                    <div>{state.meterSerialNumber}</div>
                  </div>
                  <div className='col-md-4 mb-4' />
                  <div className='col-md-8 mb-4'>
                    <label className='form-label font-regular-400'>CT Ratio</label>

                    <div className='row'>
                      <div className='col-12 col-md-4 mb-4'>
                        <div>{state.primaryCurrent} / {state.secondaryCurrent}</div>
                      </div>

                      <div className='col-12 col-md-4 mb-4'>
                        Meter Constant - {state.meterConstant}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewTC
