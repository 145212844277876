export const tcReadingRemarks = [
  {
    label: 'Meter Not Recording',
    value: 'METER_NOT_RECORDING'
  },
  {
    label: 'CT Burnt Out',
    value: 'CT_BURNT_OUT'
  },
  {
    label: 'No Display',
    value: 'NO_DISPLAY'
  },
  {
    label: 'Meter not provided',
    value: 'METER_NOT_PROVIDED'
  },
  {
    label: 'Others',
    value: 'OTHERS '
  }
]

export const executionType = [
  {
    label: 'Self',
    value: 'SELF'
  },
  {
    label: 'Department',
    value: 'DEPARTMENT'
  },
  {
    label: 'Ganga Kalyan',
    value: 'GANGA_KALYAN'
  },
  {
    label: 'General IP',
    value: 'GENERAL_IP'
  },
  {
    label: 'Shigra Samparka',
    value: 'SHIGRA_SAMPARKA'
  },
  {
    label: 'UIP',
    value: 'UIP'
  },
  {
    label: 'SCSP',
    value: 'SCSP'
  },
  {
    label: 'TSP',
    value: 'TSP'
  },
  {
    label: 'Water Works',
    value: 'WATER_WORKS'
  },
  {
    label: 'SEW',
    value: 'SEW'
  },
  {
    label: 'Service Connection',
    value: 'SERVICE_CONNECTION'
  },
  {
    label: 'Layout',
    value: 'LAYOUT'
  },
  {
    label: 'MSB',
    value: 'MSB'
  }
]

export const tcCapacity = [
  {
    label: '25 KVA',
    value: '25'
  },
  {
    label: '63 KVA',
    value: '63'
  },
  {
    label: '100 KVA',
    value: '100'
  },
  {
    label: '160 KVA',
    value: '160'
  },
  {
    label: '250 KVA',
    value: '250'
  },
  {
    label: '315 KVA',
    value: '315'
  },
  {
    label: '500 KVA',
    value: '500'
  },
  {
    label: '990 KVA',
    value: '990'
  }
]

export const readingDay = Array.from({ length: 31 }, (_, i) => i + 1) // Generate array of numbers from 1 to 31
