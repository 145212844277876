import React, { useContext, useState, useEffect, useRef } from 'react'
import bigLogo from '../../../assets/img/mescom-big-menu-logo.png'
import smallLogo from '../../../assets/img/mescom-logo.png'
import account from '../../../assets/img/account_circle.svg'
// import support from '../../../assets/img/support.svg'
import logout from '../../../assets/img/logout.svg'
import menuIcon from '../../../assets/img/menu-icon.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Auth } from 'aws-amplify'
import { AuditMonthContext } from '../../../context/AuditMonthContext'
import { Link } from 'react-router-dom'
import arrowDown from '../../../assets/img/down-arrow.svg'
import HierarchyModal from '../../atoms/HierarchyModal/HierarchyModal'
import { hierarchyDetails } from '../../../assets/offlineData/hierarchyData'
import { checkIfUserIsAE, fetchUserAttributes } from '../../../services/utils/meta'
import { HierarchyContext } from '../../../context/HierarchyContext'
import './header.scss'
import { useLocation, useHistory } from 'react-router'
// import Banner from '../../components/atoms/Banner/Banner'

const Header = ({ sideBarMinimized, signoutHandler, sidebarHandler }) => {
  const { auditMonth, setAuditMonth } = useContext(AuditMonthContext)
  const dropdownRef = useRef(null)
  const [toggle, setToggle] = useState(false)
  const { pathname } = useLocation()
  const history = useHistory()
  const [userData, setUserData] = useState({
    name: '',
    designation: '',
    area: '',
    areaName: ''
  })
  const [openHierarchyModal, setOpenHierarchyModal] = useState(false)
  const { selectedHierarchy, setSelectedHierarchy } = useContext(HierarchyContext)

  const selectedHierarchyDetails =
    selectedHierarchy !== 'All' &&
    hierarchyDetails.find((item) => item.designation === selectedHierarchy.userType.toLowerCase())

  const style = {
    display: 'block'
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggle(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    fetchHeaderDetails()
  }, [])

  const fetchHeaderDetails = async () => {
    try {
      const userAttributes = fetchUserAttributes()

      const { rank, areaName } = hierarchyDetails.find(
        (hierarchy) => hierarchy.designation === userAttributes?.['custom:userGroup']
      )

      const { area } = hierarchyDetails.find((hierarchy) => {
        if (userAttributes?.['custom:userGroup'] === 'ae') {
          return hierarchy.rank === rank
        }
        return hierarchy.rank === rank + 1
      })

      setUserData({
        name: `${userAttributes?.['custom:firstName'] ?? '---'} ${userAttributes?.['custom:lastName'] ?? '---'}`,
        designation: (
          userAttributes?.['custom:userGroup'] ?? 'Unable to fetch designation'
        )?.toUpperCase(),
        area,
        areaName:
          (userAttributes?.['custom:userGroup'] === 'dt' || userAttributes?.['custom:userGroup'] === 'cio')
            ? 'MESCOM'
            : userAttributes?.[`custom:${areaName}`] ?? ' Default Area Name'
      })
    } catch (err) {
      console.log('Error in fetching userDetails', err)
    }
  }

  const handleSignout = async () => {
    try {
      setToggle(false)
      const confirmation = window.confirm('Are you sure you want logout?')
      if (confirmation) {
        await Auth.signOut()
        window.localStorage.clear()
        signoutHandler()
        history.push('/')
      }
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  const isUserAE = checkIfUserIsAE()

  const handleHierarchyModalSubmit = (selectedUser) => {
    setOpenHierarchyModal(false)
    setSelectedHierarchy(selectedUser)
  }

  const allowedAuditMonthRoutes = ![/^\/dashboard/, /^\/profile/, /^\/file-upload/, /^\/mr-management/, /^\/feeders/, /^\/mr-management/, /^\/reports/].some(pattern => pattern.test(pathname))
  const allowedHierarchyRoutes = ![/^\/profile/, /^\/file-upload/, /^\/feeders/, /^\/reports/].some(pattern => pattern.test(pathname))

  return (
    <>
      {/* <Banner /> */}
      {/* <header className="site-header"> */}
      <nav className='navbar navbar-expand-lg navbar-light main-navbar sticky-top bg-gray'>
        <div className='container-fluid'>
          <div
            className='custom-collapse navbar-collapse'
            id='navbarTogglerDemo01'
          >
            <a
              className='navbar-brand desktop-logo d-lg-block'
              href='/'
              style={{ background: '#29304D' }}
            >
              <img
                style={{ width: 213, height: 70, margin: '0px 15px 0px 15px' }}
                src={bigLogo}
                alt='icon'
                className={`${sideBarMinimized ? 'import' : ''}`}
              />
              <img
                src={smallLogo}
                style={{
                  width: 66,
                  height: 70,
                  padding: '5px 0px 5px 0px',
                  margin: '0px 10px 0px 10px'
                }}
                alt='icon'
                className={`small-logo ${sideBarMinimized ? 'important' : ''}`}
              />
            </a>
            <button
              type='button'
              onClick={sidebarHandler}
              className='slide-left me-2'
            >
              <img
                className='img-fluid'
                src={menuIcon}
                alt='icon'
              />
            </button>

            {(allowedHierarchyRoutes && userData.designation !== 'AE') && (

              <div
                onClick={() => setOpenHierarchyModal(true)}
                // className="me-auto"
                style={{
                  border: '0.8px solid #C0C1C2',
                  gap: '4px',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  padding: '12px 12px 12px 12px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 30,
                  boxShadow: '2px 2px 7px 0px rgba(0,0,0,0.1)'
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    letterSpacing: 0.4,
                    lineHeight: '20px',
                    color: '#626467'
                  }}
                >
                  {selectedHierarchy === 'All'
                    ? `Select ${userData.area}`
                    : `Selected ${selectedHierarchy.userType ?? 'Err'} - ${
                        selectedHierarchy.location?.[selectedHierarchyDetails.location.areaCode]
                      } `}
                </span>
                <img
                  alt='missing icon'
                  src={arrowDown}
                  style={{
                    width: 22,
                    height: 22
                  }}
                />
              </div>
            )}
            {!isUserAE && (
              <HierarchyModal
                selectedGlobalHierarchy={selectedHierarchy}
                open={openHierarchyModal}
                onClose={() => setOpenHierarchyModal(false)}
                onSubmit={handleHierarchyModalSubmit}
              />
            )}
            {/* <span ">Select Zone</span> */}

            <ul className='navbar-nav me-4 ms-auto'>
              {allowedAuditMonthRoutes && // Hide audit-month for file upload screen
               (
                 <li className='nav-item roboto font-regular-400'>
                   <span className='audit-text roboto font-medium-500'>Audit Month</span>
                   <DatePicker
                     dateFormat='MMMM yyyy'
                     maxDate={new Date()}
                     showMonthYearPicker
                     showFullMonthYearPicker
                     showThreeColumnMonthYearPicker
                     placeholderText='Select'
                     selected={auditMonth}
                     onChange={(date) => setAuditMonth(date)}
                   />
                 </li>
               )}
              <hr className='vertical-line' />
              <li className='nav-item dropdown user-profile-icon' ref={dropdownRef}>
                <div
                  onClick={() => setToggle(!toggle)}
                  className='nav-link dropdown-toggle'
                  type='button'
                  id='dropdownMenuButton1'
                  data-bs-toggle='dropdown'
                  data-bs-auto-close='outside'
                  aria-expanded='false'
                  role='button'
                >
                  <span>
                    <b className='roboto font-medium-500'>{userData.name}</b>
                    <p className='mb-0 roboto font-regular-400'>
                      {userData.designation} - {userData.areaName}
                    </p>
                  </span>
                </div>
                {toggle && (
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuButton1'
                    style={style}
                  >
                    <li>
                      <Link
                        className='dropdown-item'
                        onClick={() => setToggle(!toggle)}
                        to='/profile'
                      >
                        <img
                          className='img-fluid'
                          src={account}
                          alt='icon'
                        />
                        Profile
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        style={{ paddingRight: 35 }}
                        className='dropdown-item'
                        onClick={() => setToggle(!toggle)}
                        to='/help'
                      >
                        <img
                          className='img-fluid'
                          src={support}
                          alt='icon'
                        />
                        Help / Support
                      </Link>
                    </li> */}
                    <li
                      onClick={handleSignout}
                      style={{ paddingLeft: 3 }}
                    >
                      <div
                        className='dropdown-item'
                        href=''
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          className='img-fluid'
                          src={logout}
                          alt='icon'
                        />
                        Sign out
                      </div>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* </header> */}
    </>
  )
}

export default Header
