import React, { useState, useEffect, useContext } from 'react'
import coverImage from '../../../assets/img/login.svg'
import { useParams, useHistory } from 'react-router-dom'
import editIcon from '../../../assets/img/view-selected-icon@2x.png'
import hidePwd from '../../../assets/img/eye-hide.png'
import { NotifierContext } from '../../../context/NotifierContext'
import './forgot-pwd.scss'
import { generateOtp, resetPwd } from '../../../services/api/authService'

const ForgotPassword = (props) => {
  const [formFields, setFormFields] = useState({ otp: '', newPwd: '', confirmNewPwd: '' })
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [timeLeft, setTimeLeft] = useState(30)

  const { notify, notifySuccess } = useContext(NotifierContext)

  const { username } = useParams()
  const history = useHistory()

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft])

  const submitHandler = async (event) => {
    try {
      event.preventDefault()
      const { otp, confirmNewPwd, newPwd } = formFields
      if (otp.length !== 6) {
        throw new Error('Enter 6 digit OTP')
      }
      if (newPwd === '') {
        throw new Error('Enter your new password')
      }
      if (
        !newPwd.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#@$%&'*+/=?^`{|}~-])[A-Za-z\d!#@$%&'*+/=?^`{|}~-]{8,}$/
        )
      ) {
        throw new Error(
          'New Password should at least 8 characters long with at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 digit.'
        )
      }
      if (confirmNewPwd === '') {
        throw new Error('Confirm your new password')
      }
      if (newPwd !== confirmNewPwd) {
        throw new Error('New password and confirm password must match')
      }
      setLoading(true)
      const response = await resetPwd(username, otp, newPwd)
      notifySuccess(response.message)
      history.replace('/login')
    } catch (err) {
      // if (err.message === 'Invalid code provided, please request a code again.') {
      //   notify(err.message)
      //   return
      // }
      notify(err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (field, value) => {
    setFormFields({
      ...formFields,
      [field]: value
    })
  }

  const handleResend = async (e) => {
    try {
      e.preventDefault()
      setTimeLeft(10)
      const response = await generateOtp(username)
      // notify(`OTP has been sent to ${response.CodeDeliveryDetails.Destination}`)
    } catch (err) {
      notify(err.message)
    }
  }

  return (
    <>
      <div className='unauth-main-container'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-lg-5 pe-0 ps-0'>
              <div className='login-left-image'>
                {/* <img
                  className='img-fluid'
                  src={coverImage}
                  alt='missing icon'
                /> */}
              </div>
            </div>
            <div className='col-lg-7 col-md-12 col-sm-12 pe-0 ps-0'>
              <div className='login-form-detail'>
                <div className='welcome-title text-center mb-5'>
                  <h4>Forgot / Reset Password</h4>
                  {/* <span>OTP has been sent to {id}</span> */}
                </div>
                <form>
                  <div className='mb-3 forms-input'>
                    <i
                      className='fa fa-mobile'
                      aria-hidden='true'
                    />
                    <label className='mb-2'>OTP</label>
                    <input
                      type='text'
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={({ target }) => {
                        handleInputChange('otp', target.value)
                      }}
                      value={formFields.otp}
                      placeholder='Enter OTP'
                      className='form-control'
                    />
                  </div>

                  <div className='row justify-content-center my-2'>
                    <button
                      type='submit'
                      disabled={timeLeft > 0}
                      onClick={handleResend}
                      className='resend-btn'
                    >
                      {timeLeft > 0
                        ? `RESEND in 00:${timeLeft < 10 ? '0' : ''}${timeLeft}s`
                        : 'RESEND'}
                    </button>
                  </div>

                  <div className='mb-3 forms-input'>
                    <i
                      className='fa fa-lock'
                      aria-hidden='true'
                    />
                    <label className='mb-2'>New Password</label>
                    <input
                      onChange={({ target }) => {
                        handleInputChange('newPwd', target.value)
                      }}
                      type={showNewPassword ? 'text' : 'password'}
                      value={formFields.newPwd}
                      placeholder='Enter new password'
                      name='password'
                      className='form-control'
                    />
                    <img
                      src={showNewPassword ? editIcon : hidePwd}
                      alt='missing icon'
                      style={{
                        width: 22,
                        height: 22,
                        position: 'absolute',
                        right: 18,
                        bottom: 15,
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    />
                  </div>
                  <div className='mb-4 forms-input'>
                    <i
                      className='fa fa-lock'
                      aria-hidden='true'
                    />
                    <label className='mb-2'>Confirm Password</label>
                    <input
                      onPaste={(e) => {
                        e.preventDefault()
                        return false
                      }}
                      onChange={({ target }) => {
                        handleInputChange('confirmNewPwd', target.value)
                      }}
                      type={showConfirmNewPassword ? 'text' : 'password'}
                      value={formFields.confirmNewPwd}
                      placeholder='Confirm your new password'
                      name='password'
                      className='form-control'
                    />
                    <img
                      src={showConfirmNewPassword ? editIcon : hidePwd}
                      alt='missing icon'
                      style={{
                        width: 22,
                        height: 22,
                        position: 'absolute',
                        right: 18,
                        bottom: 15,
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                    />
                  </div>
                  <button
                    type='submit'
                    onClick={submitHandler}
                    disabled={loading}
                    className='btn btn-primary mb-3  custom-active'
                  >
                    Submit {'\t'}
                    {loading && (
                      <span
                        className='spinner-grow spinner-grow-sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
