import React, { useState, useContext, useEffect } from 'react'
import UploadSection from '../../../../components/molecules/UploadSection/UploadSection'
import Button from '../../../../components/atoms/Button'
import styles from './zeroConsumption.module.scss'
import historyIcon from '../../../../assets/img/history.png'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import { NotifierContext } from '../../../../context/NotifierContext'
import { getPresignedURL, uploadFile, getDownLoadProcessedResultUrl, getUploadStatus } from '../../../../services/api/tcAuditService'
import { DateToYYYYMM } from '../../../../services/utils/date'
import { useHistory } from 'react-router-dom'
import RefreshButton from '../../../../components/atoms/RefreshButton/RefreshButton'
import InfoModal from '../../../../components/atoms/Modal/Modal'
import { fetchUserDesignation } from '../../../../services/utils/meta'

// import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'

const ZeroConsumption = ({ selectedFile }) => {
  const [loading, setLoading] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const { notifyError, notifySuccess } = useContext(NotifierContext)
  const [filUploadLoader, setFileUploadLoader] = useState(false)
  const [auditMonth, setAuditMonth] = useState(undefined)
  const [filesHistory, setFilesHistory] = useState([])
  const [modalVisible, setModalVisibility] = useState(false)

  // const [pageData, setPageData] = useState({
  //   current: 1,
  //   last: 1
  // })

  const history = useHistory()

  useEffect(() => {
    fetchFileUploadStatus(1)
  }, [])

  const loggedInUserDesignation = fetchUserDesignation()

  const fetchFileUploadStatus = async (page) => {
    try {
      setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'zeroConsumption',
        page,
        perPage: 1,
        sortBy: 'uploadDate',
        ...(loggedInUserDesignation === 'ae' && { uploadedBy: 'AE' })

      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      // setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleFileUploading = async (event) => {
    try {
      setFileUploadLoader(true)
      if (auditMonth === undefined) {
        throw new Error('Select the audit month for which you want to upload this file!')
      }

      const fileExtension = event.target.files[0].name.split('.').pop()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xlsb' && fileExtension !== 'xls') {
        throw new Error('This type of file is not allowed to upload. You can upload only files which have xls,xlsx,xlsb extensions')
      }

      const auditMonthYear = DateToYYYYMM(auditMonth)
      const response = await getPresignedURL('zeroConsumption', auditMonthYear)
      await uploadFile(event.target.files[0], response.data[fileExtension])
      notifySuccess('Successfully Uploaded the Zero Consumption report file.')
      setAuditMonth(undefined)
    } catch (err) {
      notifyError(err)
    } finally {
      setFileUploadLoader(false)
    }
  }

  // const handleLoadMore = () => {
  //   const nextPage = pageData.current + 1
  //   fetchFileUploadStatus(nextPage)
  // }

  const handleProcessingResultDownload = async (fileId) => {
    try {
      setProcessedResultLoader(fileId)
      const response = await getDownLoadProcessedResultUrl('zeroConsumption', fileId)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  return (
    <div className='container'>
      <UploadSection
        fileName={selectedFile.name}
        hasSampleFile={false}
        hasAuditMonth
        handleFileUpload={handleFileUploading}
        fileUploadDisabled={filUploadLoader}
        selectedDate={auditMonth}
        onChangeDate={setAuditMonth}
      />
      <div className='d-flex align-items-center justify-content-between mt-5 my-4'>
        <div className={styles.heading}>
          Latest file upload status (uploaded by me)
        </div>
        <RefreshButton
          onRefresh={() => { fetchFileUploadStatus(1) }}
          loading={loading}
        />

      </div>
      {loading
        ? <div>Loading....</div>
        : filesHistory.length === 0
          ? <div>No results found!</div>
          : (
            <>
              {filesHistory.map((file, index) => {
                return (
                  <div className={styles.fileStatusContainer} key={file.fileId}>
                    <FileStatus
                      index={index + 1}
                      status={file.status}
                      fileId={file.fileId}
                      sectionResult={file.sectionResult}
                      uploadedOn={file.uploadedOn}
                      message={file.message}
                      onProcessingResultDownload={() => { handleProcessingResultDownload(file.fileId) }}
                      disableProcessedResult={processedResultLoader === file.fileId}
                      count={{
                        success: file.count?.success ?? '-',
                        failure: file.count?.failure ?? '-',
                        skipped: file.count?.skipped ?? '-'
                      }}
                      onClickFailureRows={() => setModalVisibility('failure')}
                      onClickSkippedRows={() => setModalVisibility('skipped')}
                      displaySectionResultButton={loggedInUserDesignation === 'cio'}

                    />
                  </div>
                )
              })}
              {/* {!(pageData.current === pageData.last) &&
                <LoadMoreButton onClick={handleLoadMore} />} */}
            </>
            )}

      <div className={`${styles['btn-container']} mt-5`}>
        <Button
          className={`btn btn-light ${styles['upload-history-btn']}`}
          onClick={() => {
            history.push('/file-upload?tab=zeroConsumptionFileHistory')
          }}
        >
          View file upload history
          <img src={historyIcon} alt={styles['history-icon']} />
        </Button>
      </div>
      <InfoModal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'failure' &&
          (
            <>
              1. When installation with matching Account ID is not found in their O&M
              <br /><br />
              2. When TC with matching number is not found in their O&M
              <br /><br />
              3. When reading day of installation and TC do not match
              <br /><br />
              4. When MR tagged to installation is not tagged to TC
              <br /><br />
              5. When TC has already been audited for the current or future month
              <br /><br />
              6. When the TC to which the installation is already tagged has already been audited for the current or future month
              <br /><br />

            </>
          )}
        {modalVisible === 'skipped' &&
          <>
            1. Rows are skipped when the installation is already tagged to the specified TC for the specified audit month
            <br /> <br />
          </>}
      </InfoModal>
    </div>
  )
}

export default ZeroConsumption
